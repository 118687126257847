import axios from "axios";
import { Environment } from "../Environment";
import * as userService from "./user.service";
import { LabelDTO, ParagraphDTO } from "../interfaces";

export const getParagraph = async () => {
  try {
    const { data } = await axios.get(`${Environment.API_HOST}/label/paragraph`);
    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const addLabel = async (labelDTO: LabelDTO) => {
  try {
    const rand_user_hash = userService.getUser();
    const { data } = await axios.post(`${Environment.API_HOST}/label`, {
      ...labelDTO,
      rand_user_hash
    });
    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const devalidateParagraph = async (
  paragraphDTO: Partial<ParagraphDTO>
) => {
  try {
    const { data } = await axios.post(
      `${Environment.API_HOST}/label/devalidate`,
      paragraphDTO
    );
    return data;
  } catch (error) {
    console.log(error);
    return false;
  }
};
