import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.css";

ReactGA.initialize("UA-155561751-1");

ReactDOM.render(<App />, document.getElementById("root"));

serviceWorker.unregister();
