import axios from "axios";
import { Environment } from "../Environment";

export const getAnswers = async (question: string) => {
  try {
    const { data } = await axios.post(`${Environment.API_HOST}/answers`, {
      question: "sup dawg"
    });
    return data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
