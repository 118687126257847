import React from "react";

/* Highlights approximate matches */
export const Highlight = (phrase: string, keywordsString: string) => {
  const keywords = keywordsString.split(/\s/).filter(el => el.length > 3);
  const pattern = new RegExp(`(${keywords.join("|")})`, "gi");

  return (
    <span>
      {phrase.split(" ").map((part, i) => (
        <span
          key={i}
          style={
            !!part.match(pattern) && keywords.length > 0
              ? { fontWeight: "bold" }
              : {}
          }
        >
          {part}{" "}
        </span>
      ))}
    </span>
  );
};
