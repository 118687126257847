export interface AnswerDTO {
  text: string;
  ref_text: string;
  ref_doi: string;
  model_provider: string;
}

export const FeedbackStateEnum = {
  UP: "UP",
  DOWN: "DOWN",
  NONE: "NONE"
};
