import { v4 as uuidv4 } from "uuid";

export const getUser = () => {
  const userHash = localStorage.getItem("_uid");

  if (userHash === null) return "";
  return userHash;
};

export const saveUser = () => {
  if (getUser() === "") localStorage.setItem("_uid", uuidv4());
};
