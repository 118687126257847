import React, { useState } from "react";
import { GoSearch } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { encodeURL } from "../utils";

export const Intro = () => {
  let history = useHistory();
  const [formQuestion, setFormQuestion] = useState("");

  const askQuestion = () => {
    if (formQuestion.length === 0) return;
    history.push("/q/" + encodeURL(formQuestion));
  };
  return (
    <div className="container pt-6">
      <div className="row">
        <div className="col-8 mx-auto ">
          <div className="titleBox d-flex justify-content-center text-center">
            <img
              src="./static/graphics/undraw_ai_neutral.svg"
              width="200px"
              alt=""
            />
            {/* <h1 className="titleText">Covid-19 AI Search</h1> */}
          </div>
          <div className="searchBox pt-5">
            <div className="input-group input-group-lg">
              <input
                type="text"
                className="form-control "
                placeholder="How long can virus survive on surfaces"
                aria-label="How long can virus survive on surfaces"
                aria-describedby="button-addon2"
                onChange={(e: React.FormEvent<HTMLInputElement>) =>
                  setFormQuestion(e.currentTarget.value)
                }
                onKeyPress={(e: any) => {
                  if (e.key === "Enter") askQuestion();
                }}
              />
              <div className="input-group-append">
                <button
                  className="btn btn-outline-secondary"
                  type="button"
                  id="button-addon2"
                  onClick={() => askQuestion()}
                >
                  <GoSearch />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-6">
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Mission</h5>
              <p className="card-text">
                With supporting text below as a natural lead-in to additional
                content.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Special title treatment</h5>
              <p className="card-text">
                With supporting text below as a natural lead-in to additional
                content.
              </p>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Data</h5>
              <p className="card-text">
                With supporting text below as a natural lead-in to additional
                content.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
