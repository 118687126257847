import React, { useEffect } from "react";
import { Router, Switch, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { Home, Answer, Labeling } from "./pages";
import "./App.scss";
import * as userService from "./services/user.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const history = createBrowserHistory();
history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const App: React.FC = () => {
  useEffect(() => {
    //update Google analytics
    userService.saveUser();
    ReactGA.pageview(window.location.pathname);
    toast.configure();
  });
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/label" component={Labeling} />
          <Route exact path="/q/" component={Answer} />
          <Route exact path="/q/:question" component={Answer} />
          <Route component={Home} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
