import React from "react";
import { AnswerDTO, FeedbackStateEnum } from "../interfaces";
import { Highlight } from "../utils/textparsing";
import { IoIosDocument } from "react-icons/io";
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai";

type ResultsProps = {
  question: string;
  answers: AnswerDTO[];
  feedbackState: string[];
  positiveFeedback: (i: number) => void;
  negativeFeedback: (i: number) => void;
};

export const Results = ({
  question,
  answers,
  feedbackState,
  positiveFeedback,
  negativeFeedback
}: ResultsProps) => {
  return (
    <div className="row pt-5">
      <div className="col-md-8 col-sm-12 mx-auto">
        {answers.map((el, i) => {
          return (
            <div key={i} className="alert alert-primary" role="alert">
              <div className="d-flex">
                <div className="pr-1">
                  <p>{Highlight(el.text, question)}</p>
                  <div className="entered-label">
                    <a
                      href={`http://dx.doi.org/${el.ref_doi}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <IoIosDocument /> {el.ref_text}
                    </a>
                  </div>
                </div>
                <div className="ml-auto">
                  <span className="arrow-rank">
                    <AiOutlineCaretUp
                      color={
                        feedbackState[i] === FeedbackStateEnum.UP
                          ? "green"
                          : "gray"
                      }
                      onClick={() => positiveFeedback(i)}
                      size={25}
                    />
                  </span>
                  <span>
                    <AiOutlineCaretDown
                      className="arrow-rank"
                      color={
                        feedbackState[i] === FeedbackStateEnum.DOWN
                          ? "red"
                          : "gray"
                      }
                      onClick={() => negativeFeedback(i)}
                      size={25}
                    />
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
