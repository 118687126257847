import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GoSearch } from "react-icons/go";
import * as answersService from "../services/answers.service";
import * as feedbackService from "../services/feedback.service";
import { decodeURL, encodeURL } from "../utils";
import { AnswerDTO, FeedbackStateEnum } from "../interfaces";
import { Results, Header } from "../components";

export const Answer = () => {
  let history = useHistory();
  const [formQuestion, setFormQuestion] = useState("");
  const [fetchedAnswers, setFetchedAnswers] = useState<AnswerDTO[]>([]);
  const [feedbackState, setFeedbackState] = useState<string[]>([]);

  // Get :question from the URL
  let { question } = useParams();

  const updateFeedbackState = (i: number, state: string) => {
    let newFeedbackState = feedbackState;
    newFeedbackState[i] = state;
    setFeedbackState([...newFeedbackState]);
    // Send feedback
    const feedbackValue = state === "UP" ? 1 : -1;

    feedbackService.sendFeedback({
      question: formQuestion,
      answer: fetchedAnswers[i].text,
      feedback: feedbackValue,
      model_provider: fetchedAnswers[i].model_provider
    });
  };

  const positiveFeedback = (i: number) => {
    updateFeedbackState(i, FeedbackStateEnum.UP);
  };

  const negativeFeedback = (i: number) => {
    updateFeedbackState(i, FeedbackStateEnum.DOWN);
  };

  // Fetch answers from the server
  const fetchAnswers = async (q: string) => {
    const fetchedData: AnswerDTO[] = await answersService.getAnswers(q);
    setFetchedAnswers(fetchedData);

    // Initialize feedback arrows as enum FeedbackState.NONE
    setFeedbackState(
      [...Array(fetchedData.length)].map(e => FeedbackStateEnum.NONE)
    );
  };

  // Submit search
  const askQuestion = () => {
    if (formQuestion.length === 0) return;
    history.push("/q/" + encodeURL(formQuestion));
  };

  // When question changes execute fetchAnswers
  useEffect(() => {
    const decodedQuestion = decodeURL(question);
    setFormQuestion(decodedQuestion);
    fetchAnswers(decodedQuestion);
  }, [question]);

  return (
    <>
      <Header />
      <div className="container pt-5">
        <div className="row">
          <div className="col-md-8 mx-auto ">
            <div className="titleBox">
              <h1 className="titleText">Covid-19 AI Search</h1>
            </div>
            <div className="searchBox pt-4">
              <div className="input-group input-group-lg">
                <input
                  type="text"
                  className="form-control "
                  placeholder="How long can virus survive on surfaces"
                  aria-label="How long can virus survive on surfaces"
                  aria-describedby="button-addon2"
                  onChange={(e: React.FormEvent<HTMLInputElement>) =>
                    setFormQuestion(e.currentTarget.value)
                  }
                  value={formQuestion}
                  onKeyPress={(e: any) => {
                    if (e.key === "Enter") askQuestion();
                  }}
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    id="button-addon2"
                    onClick={() => askQuestion()}
                  >
                    <GoSearch />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Results
          question={decodeURL(question)}
          answers={fetchedAnswers}
          feedbackState={feedbackState}
          positiveFeedback={positiveFeedback}
          negativeFeedback={negativeFeedback}
        />
      </div>
    </>
  );
};
