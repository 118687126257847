interface Env {
  ENV_STATUS: string;
  API_HOST: string;
}

const development: Env = {
  ENV_STATUS: "development",
  //API_HOST: "http://localhost:4000"
  API_HOST: "http://3.209.238.204:4000"
};

const production: Env = {
  ENV_STATUS: "production",
  API_HOST: "http://3.209.238.204:4000"
};

const config =
  process.env.REACT_APP_ENV === "development" ? development : production;

export const Environment = {
  ...config
};
