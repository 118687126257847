import React, { useState, useEffect } from "react";
import { FaTimes, FaForward } from "react-icons/fa";
import { IoMdDoneAll } from "react-icons/io";
import * as labelService from "../services/label.service";
import { ParagraphDTO, LabelDTO } from "../interfaces";
import { Loader } from "../components";
import { toast } from "react-toastify";

export const Labeling = () => {
  const [isLoading, setLoadingState] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [questions, setQuestions] = useState<string[]>([""]);
  const [paragraphData, setParagraphData] = useState<ParagraphDTO>({
    text: "",
    par_index_in_paper: "",
    paper_sha: ""
  });

  const handleSubmit = async () => {
    if (questions.reduce((acc, elem) => acc + elem.length, 0) < 10) {
      toast.error(
        "Your questions are too short. You can always skip the paragraph.",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true
        }
      );
      return;
    }
    const labelDTO: LabelDTO = {
      paper_sha: paragraphData.paper_sha,
      par_index_in_paper: paragraphData.par_index_in_paper,
      questions
    };

    setLoadingState(true);
    const ans = await labelService.addLabel(labelDTO);
    clearInterface();
    updateParagraph();
    toast.success("Questions received.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true
    });
  };

  const handleDevalidate = async () => {
    setLoadingState(true);
    let res = await labelService.devalidateParagraph({
      paper_sha: paragraphData.paper_sha,
      par_index_in_paper: paragraphData.par_index_in_paper
    });
    updateParagraph();
  };

  const handleQuestionChange = (id: number, evt: any) => {
    const newQuestions = questions.map((q, qid) => {
      if (id !== qid) return q;
      return evt.target.value;
    });
    setQuestions(newQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, ""]);
  };

  const handleRemoveQuestions = (id: number) => {
    setQuestions(questions.filter((q, el_id) => id !== el_id));
  };

  const updateParagraph = async () => {
    setLoadingState(true);
    const p: ParagraphDTO | false = await labelService.getParagraph();
    if (p === false) {
      setErrorMessage("Cannot connect to server.");
      setLoadingState(false);
      return;
    }
    clearInterface();
    setParagraphData(p);
    setLoadingState(false);
  };

  const clearInterface = async () => {
    setQuestions([""]);
    setParagraphData({
      text: "",
      par_index_in_paper: "",
      paper_sha: ""
    });
  };

  useEffect(() => {
    updateParagraph();
  }, []);

  return (
    <div>
      <div className="container-fluid pt-5 pb-5 bg-light-yellow ">
        <div className="row">
          <div className="col-md-8 col-sm-12 mx-auto text-center">
            <h1>You can help to make AI smarter</h1>
          </div>
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="container pt-5 pb-5">
          <div className="row fade-in-top">
            <div className="col-md-8 col-sm-12 mx-auto">
              {errorMessage && (
                <div className="alert alert-info" role="alert">
                  Ops. {errorMessage}
                </div>
              )}
              {paragraphData.text}
            </div>
          </div>
          {!errorMessage && (
            <div>
              <div className="row pt-5">
                <div className="col-md-8 col-sm-12 mx-auto">
                  {questions.map((q, id) => (
                    <div className="input-group mb-3" key={id}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={`Question #${id + 1}`}
                        value={q}
                        onChange={evt => handleQuestionChange(id, evt)}
                        aria-label="Example text with button addon"
                        aria-describedby="button-addon1"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-outline-danger"
                          onClick={evn => handleRemoveQuestions(id)}
                          type="button"
                          id="button-addon1"
                        >
                          <FaTimes />
                        </button>
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={() => handleAddQuestion()}
                    className="btn btn-outline-secondary"
                  >
                    New Question
                  </button>
                </div>
              </div>
              <div className="row pt-5">
                <div className="col-md-8 col-sm-12 mx-auto">
                  <div
                    className="btn-group btn-group-lg w-100"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      onClick={() => {
                        updateParagraph();
                      }}
                      type="button"
                      className="btn btn-warning w-50"
                    >
                      <FaForward /> Skip paragraph
                    </button>

                    <button
                      onClick={() => handleDevalidate()}
                      type="button"
                      className="btn btn-danger w-50"
                    >
                      Invalid paragraph
                    </button>

                    <button
                      onClick={() => handleSubmit()}
                      type="button"
                      className="btn btn-success w-50"
                    >
                      Submit <IoMdDoneAll />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
