export const encodeURL = (question: string | undefined) => {
  if (typeof question == "undefined") return "";
  return encodeURIComponent(question)
    .split("%20")
    .join("-");
};

export const decodeURL = (question: string | undefined) => {
  if (typeof question == "undefined") return "";
  return decodeURIComponent(question.split("-").join("%20"));
};

// export const encodeURL = (question: string | undefined) => {
//     if (typeof question == "undefined") return "";
//     return question.split(" ").join("-");
//   };

//   export const decodeURL = (question: string | undefined) => {
//     if (typeof question == "undefined") return "";
//     return question.split("-").join(" ");
//   };
