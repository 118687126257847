import React from "react";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="header float-right">
      <ul className="list-inline">
        <li className="list-inline-item">
          <Link to="/" className="headerLink">
            {" "}
            Home
          </Link>
        </li>
        <li className="list-inline-item">
          <Link to="/about" className="headerLink">
            About
          </Link>
        </li>
      </ul>
    </div>
  );
};
