import axios from "axios";
import { Environment } from "../Environment";
import * as userService from "./user.service";

interface FeedbackDTO {
  question: string;
  answer: string;
  feedback: number;
  model_provider: string;
}

export const sendFeedback = async (feedbackDTO: FeedbackDTO) => {
  try {
    const rand_user_hash = userService.getUser();
    const { data } = await axios.post(`${Environment.API_HOST}/feedback`, {
      ...feedbackDTO,
      rand_user_hash
    });
    return data;
  } catch (error) {
    console.log(error);
  }
};
