import React from "react";
import { Header } from "../components/Header";
import { Intro } from "../components/Intro";

export const Home = () => {
  return (
    <>
      <Header />
      <Intro />
    </>
  );
};
